import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { cn } from "@/lib/utils";
import DashboardNavbar, { ILinks } from "components/DashboardNavbar";
import { Link, Outlet, useLocation } from "react-router-dom";

export interface INavLinks {
  current: {
    label: string;
    icon: string;
  };
  left: ILinks[];
  right: ILinks[];
}

const Remit = () => {
  const location = useLocation();
  const navLinks: INavLinks = {
    current: {
      label: "Remit",
      icon: "/images/sidebar_img/remit.svg",
    },
    left: [
      // {
      //     "label": "Remit",
      //     "href": "/overview",
      //     "icon": "images/overview/remit.svg",
      //     "logo": "images/overview/remit-text.svg"
      // },
      // {
      //     "label": "Forex",
      //     "href": "/overview",
      //     "icon": "images/overview/forex.svg",
      //     "logo": "images/overview/forex-text.svg"
      // },
      // {
      //     "label": "Study Abroad",
      //     "href": "/overview",
      //     "icon": "images/overview/study.svg",
      //     "logo": "images/overview/study-text.svg"
      // },
      // {
      //     "label": "Corporate",
      //     "href": "/overview",
      //     "icon": "images/overview/corporate.svg",
      //     "logo": "images/overview/corporate-text.svg"
      // }
    ],
    right: [
      {
        label: "Profile",
        visible: false,
        href: "#",
        icon: "/images/overview/profile.svg",
        submenu: [
          {
            label: "My Profile",
            href: "#",
            icon: "/images/overview/myprofile.svg",
          },
          {
            label: "Logout",
            href: "/logout",
            icon: "/images/overview/logout.svg",
          },
        ],
      },
    ],
  };

  const components: { title: string; href: string; description: string }[] = [
    {
      title: "Overview",
      href: "/remit",
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
    {
      title: "Deals",
      href: "deals",
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
    {
      title: "Orders",
      href: "orders",
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
  ];

  return (
    <div className=" flex flex-col h-screen gap-2.5 font-inter">
      <DashboardNavbar navLinks={navLinks}>
        <NavigationMenu className="">
          <NavigationMenuList className="flex gap-2">
            {components.map((component) => {
              const isActive =
                component.title === "Overview"
                  ? location.pathname === component.href
                  : location.pathname.split("/").at(-1) === component.href;
              return (
                <Link
                  key={crypto.randomUUID()}
                  className="h-full"
                  to={component.href}
                >
                  <NavigationMenuItem
                    className={cn(
                      isActive
                        ? "border bg-white-A700 text-blue-700 border-blue-700 rounded-lg"
                        : "border-transparent",
                      "py-2.5 px-4 border",
                    )}
                  >
                    <NavigationMenuLink className="font-semibold text-sm ">
                      {component.title}
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                </Link>
              );
            })}
          </NavigationMenuList>
        </NavigationMenu>
      </DashboardNavbar>
      <div className="flex-1 bg-white-A700 sm:flex-col overflow-hidden flex w-full rounded-xl p-3 border border-[#D9E0F7]">
        <Outlet />
      </div>
    </div>
  );
};

export default Remit;
