import { Search } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  setCompanyName,
  setCustomerContactPhone,
  setEntityName,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReactSelect, {
  MenuProps,
  SingleValue,
  components,
  OptionProps,
  StylesConfig,
} from "react-select";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { useState } from "react";
import { AxiosRequestConfig } from "axios";
import { getCompany, GetContactReqType } from "service/apis/Opportunities";
import { searchPlaceholderText } from "./utils/featureRule";
import { toast, Toaster } from "sonner";

export default function CreateOpportunity() {
  const dispatch = useAppDispatch();
  const { company_name, pipelineType, customer_contact_phone,entityName } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [companyDetails, setCompanyDetails] = useState<Company[]>([]);
  const [exactMatch, setExactMatch] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchOptions = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        query: inputValue,
        pipelineType:
          pipelineType === "corporate" ? "coorporate" : pipelineType,
      },
    };
    try {
      if (inputValue === "") return;
      const response = (await getCompany(payload)) as any;
      setCompanyDetails(response?.data?.items);
      if (pipelineType === "corporate") {
        if (response?.data?.items?.length === 0) {
          setError("No data found");
          return;
        }
        setOptions(() =>
          response?.data?.items?.map((item: any) => ({
            value: item.id,
            label: item.company_name,
            contact_person: item?.contacts_id?.[0]?.[0]?.contactName,
            company_email: item?.email,
            ...item,
          })),
        );
      } else {
        if (response?.data?.length === 0) {
          setError("No data found");
          return;
        }
        const newOptions = response?.data?.map((item: any) => ({
          value: item?.id,
          label: item?.contactName,
          contact_person: item?.contactName,
          ...item,
        }));
        setOptions(newOptions);
        // Check for exact match
        setExactMatch(
          newOptions.some(
            (option: any) =>
              option.label.toLowerCase() === inputValue.toLowerCase(),
          ),
        );
      }
      // setCompanyDetails(response?.data?.items)
      // setOptions(()=>response?.data?.items?.map((item:any) => ({ value: item?.id as string, label: item?.company_name })));
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleChange = (newValue: SingleValue<Option>) => {
    setSelectedOption(() => newValue);
    if (newValue && pipelineType === "corporate") {
      const selectedCompany = companyDetails.find(
        (company: any) => company.id === newValue.value,
      );
      navigate(`/opportunities/opportunitydetails/${newValue.value}`, {
        state: { companyDetails: selectedCompany },
      });
    } else if (newValue) {
      navigate(`/opportunities/opportunitydetails/${newValue.value}`);
    }
  };

  const handleInputChange = (newValue: string) => {
    if (newValue.length === 0) {
      setError(null);
      setOptions([]);
    }
    setInputValue(newValue);
    switch (pipelineType) {
      case "retail":
        dispatch(setCustomerContactPhone(inputValue));
        break;
      case "corporate":
        dispatch(setCompanyName(inputValue));
        break;
      case "partner":
        dispatch(setEntityName(inputValue));
        break;
      default:
        break;
    }
  };

  const onClickAddNewContact = () => {
    switch (pipelineType) {
      case "retail":
        if (
          customer_contact_phone.length === 10 &&
          customer_contact_phone.match(/^[0-9]+$/) != null
        ) {
          navigate("/opportunities/createcontact");
        } else
          toast.error("Please enter a valid phone number", {
            description:
              "To add a retail opportunity, valid phone number is required",
          });
        break;
      case "corporate":
        if(company_name.length === 0) {
          toast.error("Please enter a company name", {
            description:
              "To add a corporate opportunity, company name is required",
          });
          return;
        }
        navigate("/opportunities/createcontact");
        break;
      case "partner":
        if(entityName.length === 0) {
          toast.error("Please enter an entity name", {
            description:
              "To add a partner opportunity, entity name is required",
          });
          return;
        }
        navigate("/opportunities/createcontact");
        break;
      default:
        break;
    }
  };

  useDebounce(() => fetchOptions(), 500, [inputValue]);

  const customStyles: StylesConfig<Option, false> = {
    control: (provided, state) => ({
      ...provided,
      zIndex: 0,
      borderRadius: "1.75rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "40px",
      borderColor: state.isFocused ? "#165FE3" : "#d2d2d2",
      boxShadow: "none",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }),
  };

  return (
    <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
      <div className=" flex w-full justify-between items-center">
        <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Add {pipelineType} opportunities
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            Search or add new contact to create the {pipelineType} opportunity.
          </p>
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between w-full items-center">
          <div className="flex gap-2 w-full items-center">
            <div className="relative w-full flex items-center flex-grow ">
              <ReactSelect<Option, false>
                placeholder={searchPlaceholderText[pipelineType]}
                styles={customStyles}
                components={{
                  Menu: CustomMenu,
                  Option: CustomOption,
                }}
                isClearable
                isSearchable
                options={options}
                onInputChange={handleInputChange}
                onChange={handleChange}
                value={selectedOption}
                name="company"
                className="w-full max-w-lg"
                filterOption={null}
              />
              <Search className="absolute left-2 h-3.5" />
            </div>
          </div>
          {!exactMatch && (
            <button
              onClick={onClickAddNewContact}
              type="button"
              className="px-4 sm:hidden py-[11px] max-w-[180px] w-full rounded-lg bg-blue-700 text-white-A700 border"
            >
              New Contact
            </button>
          )}
        </div>
      </div>
      {!error && pipelineType === "corporate" && inputValue.length ? (
        <div className="flex flex-col gap-3 w-full">
          <div className="py-2 px-4 rounded-3xl bg-[#F0F6FF] w-fit text-blue-700">
            Company: {company_name}
          </div>
        </div>
      ) : (
        options.length === 0 && (
          <div className="flex gap-2 flex-col items-center rounded-lg justify-center p-5 h-1/2 w-full bg-[#FAFAFA]">
            <div className="">
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                  fill="#666666"
                />
              </svg>
            </div>
            <small>No contact found!</small>
            <small>Please add new contact.</small>
          </div>
        )
      )}
      { !exactMatch && (
        <div className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700">
          <button
            onClick={onClickAddNewContact}
            type="button"
            className="px-4 py-[11px] w-full rounded-lg bg-blue-700 text-white-A700 border"
          >
            New Contact
          </button>
        </div>
      )}
      <Toaster position="top-right" theme="dark" />
    </div>
  );
}

interface Company {
  id: string;
  company_name: string;
  contact_person: string;
  company_email: string;
}

interface Option extends Company {
  value: string;
  label: string;
}

const CustomMenu: React.FC<MenuProps<Option, false>> = ({
  children,
  ...props
}) => {
  return (
    <components.Menu {...props}>
      <table className="w-full border-collapse text-center">
        <thead className="w-full">
          <tr className="w-full p-2 flex items-center justify-between text-[#C2C2C2] text-sm leading-5">
            {props.options.company_name ? (
              <>
                <th className="font-normal">Company Name</th>
                <th className="font-normal">Contact Person</th>
                <th className="font-normal">Company Email</th>
              </>
            ) : (
              <>
                <th className="font-normal">Name</th>
                <th className="font-normal">Number</th>
                <th className="font-normal">Email</th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="w-full">{children}</tbody>
      </table>
    </components.Menu>
  );
};

const CustomOption: React.FC<OptionProps<Option, false>> = ({
  children,
  ...props
}) => {
  return (
    <components.Option {...props}>
      <tr
        className={` w-full flex justify-between items-start ${props.isFocused ? "bg-blue-100" : props.isSelected ? "bg-blue-200" : "bg-white"}`}
      >
        {props.data.company_name ? (
          <>
            {" "}
            <td className="">{props.data.company_name}</td>
            <td className="">{props.data.contact_person}</td>
            <td className="">{props.data.company_email}</td>
          </>
        ) : (
          <>
            <td>{props.data.contactName}</td>
            <td>{props.data.contactNumber}</td>
            <td>{props.data.contactEmail}</td>
          </>
        )}
      </tr>
    </components.Option>
  );
};
